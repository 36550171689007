<script setup lang="ts">
defineProps<{
  type: 'accordion'
  title?: string
  subtitle?: string
  image?: {
    src?: {
      rawUrl?: string
      accessibilityText?: string
    }
  }
  text?: string
  languageCode?: string
}>()

const open = ref(false)

function toggleAccordion() {
  open.value = !open.value
}
</script>

<template>
  <div class="df-accordion">
    <div id="dfAccordionWrapper">
      <span id="screenReaderMessage" class="visually-hidden">
        <!--        -->
      </span>

      <button
        id="accordion-button"
        class="top-row inline-flex cursor-pointer border-none bg-transparent align-bottom c-slate-900 decoration-none focus-outline"
        aria-controls="accordion-content"
        :aria-expanded="open"
        @click="toggleAccordion"
      >
        <div class="content">
          <div v-if="image?.src?.rawUrl" class="image-content">
            <img id="image" :src="image.src.rawUrl" :alt="image?.src?.accessibilityText || ''">
          </div>

          <div v-if="title || subtitle" class="text-content">
            <h3 v-if="title" id="title" class="word-wrap">
              {{ title }}
            </h3>

            <div v-if="subtitle" id="subtitle" class="word-wrap">
              {{ subtitle }}
            </div>
          </div>
        </div>

        <div class="expand-icon">
          <NIcon
            id="expandIcon"
            class="text-context"
            n="slate5 dark:slate4 !2xl"
            aria-hidden="true"
            icon="i-heroicons:chevron-right-solid"
            :class="{ open }"
          />
        </div>
      </button>

      <div v-if="open" id="accordion-content" class="text-row" aria-labelledby="title" role="region">
        <div id="text" class="word-wrap">
          {{ text }}
        </div>
      </div>
    </div>
  </div>
</template>
