import emojiRegex from 'emoji-regex'

const emojiRE = emojiRegex()

export function useEmojiNormalizer() {
  const normalizeEmojiDisplay = (value: string) => {
    return value ? value.replace(emojiRE, '<span style="font-family: none;">$&</span>') : value
  }

  return {
    normalizeEmojiDisplay,
  }
}
