<script setup lang="ts">
defineOptions({ inheritAttrs: false })

defineProps<{
  isBot?: boolean
}>()

const attrs = useAttrs()
</script>

<template>
  <div class="df-message" :class="{ isbot: !!isBot }">
    <div
      class="message border-px"
      :class="[
        attrs.class,
        isBot
          ? 'bot-message n-text-body bg-slate-100 border-slate-100'
          : 'user-message text-white bg-context border-context/8',
      ]"
    >
      <div class="inline-flex">
        <div v-for="dot in 3" :key="dot" class="mx-px block h-2 w-2 rounded-full bg-slate-400" :class="`typing-dot-${dot}`" />
      </div>
    </div>
  </div>
</template>

<style lang="postcss">
.typing-dot-1 {
  animation: jumping-dots 1000ms ease infinite;
}
.typing-dot-2 {
  animation: jumping-dots 1000ms ease 100ms infinite;
}
.typing-dot-3 {
  animation: jumping-dots 1000ms ease 200ms infinite;
}
@keyframes jumping-dots {
  0% {
    transform: translateY(0);
    opacity: 0.85;
  }
  28% {
    transform: translateY(-4px);
    opacity: 0.85;
  }
  44% {
    transform: translateY(0);
    opacity: 0.95;
  }
}
</style>
