<script setup lang="ts">
const { t } = useI18n()
const { closeChat } = useSupportAgent()
</script>

<template>
  <div class="df-messenger-titlebar">
    <div class="title-wrapper border-b n-border-base px2 n-bg-base !shadow-sm !sm:rounded-t-3xl">
      <h2 id="dfTitlebar" class="focus-outset focus-outline-contrast" tabindex="-1">
        <!--        Order and account management -->
      </h2>

      <NButton
        id="minimizeIconButton"
        class="ml-auto mr-1 rounded-full bg-slate-900/5 p0.025em text-context !focus-visible:n-focus-base children:m0"
        :aria-label="t('chat.aria.minimize')"
        icon="i-heroicons:minus-20-solid"
        n="2xl slate9 "
        @click="closeChat"
      />
    </div>
  </div>
</template>
