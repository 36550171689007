<script setup lang="ts">
const { messenger } = useSupportAgent()
const { t } = useI18n()
</script>

<template>
  <div id="df-chat-wrapper">
    <div
      class="fixed bottom-0 right-0 h-full w-full flex flex-col sm:h-xl sm:w-sm sm:translate-y-[-105px] sm:transform sm:rounded-3xl sm:shadow-lg sm:ring sm:ring-black/5 sm:-translate-x-6 n-bg-base"
      role="dialog"
      :aria-label="t('chat.aria.chat_window')"
    >
      <template v-if="!messenger.minimized">
        <MessengerTitlebar class="z2" />
        <MessageList class="min-h-0 min-w-[250px] flex flex-auto flex-col" />
        <MessengerUserInput />
      </template>
      <!-- -->
    </div>
  </div>
</template>
