<script setup lang="ts">
const props = defineProps<{
  type: 'image'
  rawUrl?: string
  accessibilityText?: string
  languageCode?: string
}>()
</script>

<template>
  <div v-if="props.rawUrl" class="df-image">
    <img :src="props.rawUrl" :alt="props.accessibilityText">
  </div>
</template>
