<script setup lang="ts">
const props = defineProps<{
  type: 'description'
  title?: string
  text?: string[]
  languageCode?: string
}
>()
</script>

<template>
  <div class="df-description">
    <div id="descriptionWrapper">
      <h3 v-if="props.title" class="title">
        {{ props.title }}
      </h3>

      <div v-for="(line, index) in props.text" :key="index" class="description-line">
        {{ line }}
      </div>
    </div>
  </div>
</template>
