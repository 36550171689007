<script setup lang="ts">
const slots = useSlots()
const { t } = useI18n()

const role = computed(() => {
  const children = slots?.default?.()?.[0]?.children

  if (children && Array.isArray(children) && children.length > 1)
    return { role: 'list' }

  return {}
})
</script>

<template>
  <div class="df-card" v-bind="role">
    <div class="card-wrapper border-px rounded-2xl shadow-sm n-bg-base">
      <span class="sr-only">
        {{ t('chat.agent_says') }}
      </span>

      <slot />
    </div>
  </div>
</template>
