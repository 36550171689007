<script setup lang="ts">
import type { GcxQueryInput, GcxQueryParameters } from '#graphql-operations'

const props = defineProps<{
  type: 'chips'
  options?: Array<{
    mode?: 'blocking' | string
    text?: string
    link?: string
    image?: {
      src?: {
        rawUrl?: string
        accessibilityText?: string
      }
    }
    event?: {
      name?: string
      parameters?: { [key: string]: unknown }
    }
  }>
  languageCode?: string
}
>()

const { t } = useI18n()
const { sendMessage, addMessage, messenger } = useSupportAgent()
const clicked = ref(false)

async function onClick(chip: NonNullable<typeof props.options>[number]) {
  clicked.value = true

  if (!chip.link) {
    if (chip.event?.name === 'SELECT_DISPUTE_REASON' && chip.event.parameters?.dispute_reason) {
      const queryInput = {
        event: { event: chip.event.name },
        languageCode: props.languageCode ?? messenger.value.languageCode ?? 'en',
      }

      const queryParams: GcxQueryParameters = {
        parameters: { dispute_reason: chip.event.parameters.dispute_reason },
      }

      await addMessage({
        type: 'text',
        isBot: false,
        languageCode: queryInput.languageCode,
        text: chip.text,
      })

      return await sendMessage(queryInput, queryParams)
    }

    const queryInput: GcxQueryInput = {
      text: { text: chip.text || '' },
      languageCode: props.languageCode ?? messenger.value.languageCode ?? 'en',
    }

    await sendMessage(queryInput)
  }
}

const { normalizeEmojiDisplay } = useEmojiNormalizer()
</script>

<template>
  <div v-if="!clicked" class="df-chips">
    <div class="df-chips-wrapper" role="list">
      <span class="sr-only">
        {{ t('chat.agent_says') }}
      </span>

      <template v-if="options?.length">
        <span v-for="(chip, index) in options" :key="index" role="listitem">
          <a
            v-if="chip.link"
            :href="chip.link"
            class="focus-outline-round chip chip-link inline-flex cursor-pointer rounded-full border-none bg-transparent align-bottom c-slate-900 decoration-none shadow ring ring-black/5 hover:n-button-hover"
            target="_blank"
            rel="noopener noreferrer"
            @click="onClick(chip)"
          >
            <img
              v-if="chip.image?.src?.rawUrl"
              aria-hidden="true"
              :src="chip.image.src.rawUrl"
              :alt="chip.image.src?.accessibilityText ?? ''"
            >

            <span v-if="chip.text" v-html="normalizeEmojiDisplay(chip.text)" />

            <span class="visually-hidden">
              {{ t('chat.opens_in_a_new_tab') }}
            </span>
          </a>

          <button
            v-else
            class="chip focus-outline-round inline-flex cursor-pointer rounded-full border-none bg-transparent align-bottom text-sm text-slate-800 c-slate-900 decoration-none shadow ring ring-black/5 hover:bg-slate-50 hover:text-slate-900"
            @click="onClick(chip)"
          >
            <img
              v-if="chip.image?.src?.rawUrl"
              aria-hidden="true"
              :src="chip.image.src.rawUrl"
              :alt="chip.image.src?.accessibilityText ?? ''"
            >

            <span v-if="chip.text" v-html="normalizeEmojiDisplay(chip.text)" />

            <span class="visually-hidden">
              {{ t('chat.opens_in_a_new_tab') }}
            </span>
          </button>
        </span>
      </template>
    </div>
  </div>
</template>
