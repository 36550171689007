<script setup lang="ts">
import { useVModel } from '@vueuse/core'
import type { GcxQueryInput, GcxQueryParameters } from '#graphql-operations'

const props = defineProps<{
  modelValue?: string
  placeholder?: string
  icon?: string
}>()

const emit = defineEmits<{ (...args: any): void }>()
const { locale, t } = useI18n()
const { sendMessage, messenger, validateInput, clearCustomerToken } = useSupportAgent()

const input = useVModel(props, 'modelValue', emit, { passive: true })
const inputRef = ref<HTMLInputElement | null>(null)
const uploadedFiles = ref<Record<string, string>[]>([])
const isUploading = ref(false)

const isValid = computed(() => {
  const lastMessage = messenger.value.messages?.[messenger.value.messages.length - 1]

  if (lastMessage?.type === 'button' || lastMessage?.type === 'chips') {
    if (lastMessage?.type === 'button' && lastMessage.mode === 'blocking')
      return false

    if (lastMessage?.type === 'chips' && lastMessage.options?.some(option => option.mode === 'blocking'))
      return false
  }

  if (!messenger.value.messages?.length || (messenger.value.showFileUpload && uploadedFiles.value.length && !isUploading.value))
    return true

  return validateInput(input.value)
})

async function handleSubmit() {
  messenger.value.languageCode ??= ['en', 'cs'].includes(locale.value) ? locale.value : 'en'

  if (uploadedFiles.value.length && !isUploading.value) {
    const queryInput: GcxQueryInput = {
      event: { event: 'FILE_UPLOAD' },
      languageCode: messenger.value.languageCode,
    }

    const queryParams: GcxQueryParameters = {
      parameters: {
        file_ids: uploadedFiles.value.map(file => file.id),
      },
    }

    uploadedFiles.value = []
    messenger.value.showFileUpload = false
    clearCustomerToken()

    await sendMessage(queryInput, queryParams)
    return
  }

  const queryInput: GcxQueryInput = {
    text: { text: input.value! },
    languageCode: messenger.value.languageCode,
  }

  if (isValid.value) {
    input.value = ''
    inputRef.value?.focus()
    await sendMessage(queryInput)
  }
}
</script>

<template>
  <div class="df-messenger-user-input">
    <div class="input-container sm:overflow-hidden sm:rounded-b-3xl">
      <div class="input-box-wrapper flex px-2 pb-safe !h-[[calc(14rem+env(safe-area-inset-bottom))]]">
        <div class="relative grow-1 overflow-x-auto">
          <div class="border n-border-base rounded-6 bg-slate-900/5 dark:bg-white/5">
            <!--            <MessengerFiles v-if="messenger.showFileUpload" class="overflow-x-auto p3" /> -->
            <MessengerFiles
              v-if="messenger.showFileUpload"
              v-model:files="uploadedFiles"
              v-model:uploading="isUploading"
              class="overflow-x-auto p3"
            />
            <div>
              <div class="n-text-input flex flex-auto items-center">
                <input
                  ref="inputRef"
                  v-model="input"
                  type="text"
                  :placeholder="placeholder ?? t('chat.ask_something')"
                  :aria-label="t('chat.aria.talk_to_agent')"
                  class="w-full flex-auto bg-transparent py2 !text-base !outline-none !sm:text-sm"
                  @keydown.enter="handleSubmit"
                >
              </div>
            </div>
          </div>
        </div>

        <div class="inline-flex -mr2">
          <button
            id="sendIconButton"
            class="n-button relative mr2 inline-flex n-button-base basis-auto flex-row cursor-pointer cursor-pointer rounded-full border-none active:n-button-active bg-transparent p0 align-bottom c-slate-900 decoration-none focus-visible:n-focus-base !inline-flex !scale-100 hover:n-button-hover n-disabled:n-disabled"
            :aria-label="t('chat.aria.send')"
            @click="handleSubmit"
          >
            <NIcon
              id="sendIcon"
              icon="i-heroicons:paper-airplane-20-solid"
              :n="isValid ? 'xl' : 'slate4 xl'"
              class="text-context"
            />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
