<script setup lang="ts">
import { micromark } from 'micromark'

defineOptions({ inheritAttrs: false })

const props = defineProps<{
  type: 'text'
  text?: string
  isBot?: boolean
  languageCode?: string
}>()

const { t } = useI18n()
const attrs = useAttrs()

const { normalizeEmojiDisplay } = useEmojiNormalizer()

const formattedText = computed(() => {
  const textContent = (props.text ?? '').trim()
  return props.isBot ? micromark(textContent) : textContent
})
</script>

<template>
  <div class="df-message" :class="{ isbot: !!isBot }">
    <div
      class="message border-px"
      :class="[
        attrs.class,
        isBot
          ? 'bot-message n-text-body bg-slate-100 border-slate-100'
          : 'user-message text-white bg-context/80 border-context/80',
      ]"
    >
      <span class="sr-only">
        {{ isBot ? t('chat.agent_says') : t('chat.i_say') }}
      </span>
      <span v-html="normalizeEmojiDisplay(formattedText)" />
    </div>
  </div>
</template>
