<script setup lang="ts">
const props = defineProps<{
  type: 'info'
  title?: string
  subtitle?: string
  image?: {
    src?: {
      rawUrl?: string
      accessibilityText?: string
    }
  }
  actionLink?: string
  languageCode?: string
}>()
const { t } = useI18n()
</script>

<template>
  <div class="df-title">
    <a
      target="_blank"
      rel="noopener noreferrer"
      class="link-wrapper inline-flex cursor-pointer border-none bg-transparent align-bottom c-slate-900 decoration-none focus-outline"
      :href="props.actionLink"
    >
      <div class="title-card-elements">
        <img
          v-if="props?.image?.src?.rawUrl"
          :alt="props?.image?.src?.accessibilityText || props.title || props.subtitle || ''"
          class="image" :style="{
            backgroundImage: `url(${props.image.src.rawUrl})`,
          }"
        >

        <div v-if="props.title || props.subtitle" class="text-container">
          <h3 v-if="props.title" class="title">
            {{ props.title }}
          </h3>

          <div v-if="props.subtitle" class="subtitle">
            {{ props.subtitle }}
          </div>

        </div>

        <span class="sr-only">
          {{ t('chat.opens_in_a_new_tab') }}
        </span>
      </div>
    </a>
  </div>
</template>
