<script setup lang="ts">
const { messenger, maximizeChat } = useSupportAgent()
const { t } = useI18n()

function onFocusIn() {
  //
}

function onKeydown(_event: KeyboardEvent) {
  //
}

function toggleChat() {
  if (messenger.value.minimized)
    maximizeChat()
  else
    messenger.value.expand = !messenger.value.expand
}
</script>

<template>
  <div>
    <div class="df-messenger" :data-expand="messenger.expand">
      <div
        class="fixed bottom-4 right-4 z50 bg-white text-slate-900"
        :class="{ expanded: messenger.expand }"
        @keydown="onKeydown"
      >
        <div id="topFocusTrap" class="block sm:hidden" tabindex="0" @focusin="onFocusIn" />

        <Transition name="scale-in-br" mode="out-in">
          <MessengerChat v-if="messenger.expand" />
        </Transition>

        <button
          id="widgetIcon"
          class="focus-outset focus-circle focus-outline-round relative flex items-center justify-center bg-context"
          aria-controls="df-chat-wrapper"
          :aria-expanded="!messenger.minimized"
          :aria-label="t('chat.aria.open_chat')"
          @click="toggleChat"
        >
          <Transition name="slide-in-fwd-center" mode="out-in">
            <NIcon v-if="messenger.expand" icon="i-heroicons:x-mark-20-solid" n="bg-base 2xl" />
            <NIcon v-else icon="i-heroicons:question-mark-circle" n="bg-base 3xl" />
          </Transition>
        </button>

        <div id="bottomFocusTrap" class="block sm:hidden" tabindex="0" @focusin="onFocusIn" />
      </div>
    </div>
  </div>
</template>

<style>
/* open window */
.scale-in-br-enter-active {
  animation: scale-in-br 0.2s ease both;
}

.scale-in-br-leave-active {
  animation: scale-in-br 0.125s ease both reverse;
}

@keyframes scale-in-br {
  0% {
    transform: scale(0);
    transform-origin: 100% 100%;
    opacity: 0;
  }
  100% {
    transform: scale(1);
    transform-origin: 100% 100%;
    opacity: 1;
  }
}

/* icon swap */
.slide-in-fwd-center-enter-active {
  animation: slide-in-fwd-center 0.15s cubic-bezier(0.785, 0.135, 0.15, 0.86)
    both;
}

.slide-in-fwd-center-leave-active {
  animation: slide-in-fwd-center 0.15s cubic-bezier(0.785, 0.135, 0.15, 0.86)
    reverse both;
}

@keyframes slide-in-fwd-center {
  0% {
    transform: scale(0) rotate(0);
    opacity: 0;
  }
  100% {
    transform: scale(1) rotate(360deg);
    opacity: 1;
  }
}

:root {
  --df-messenger-bot-message: #ffffff;
  --df-messenger-button-titlebar-color: #1e88e5;
  --df-messenger-button-titlebar-font-color: white;
  --df-messenger-chat-background-color: #fafafa;
  --df-messenger-font-color: rgba(0, 0, 0, 0.87);
  --df-messenger-input-box-color: white;
  --df-messenger-input-font-color: rgba(0, 0, 0, 0.87);
  --df-messenger-input-placeholder-font-color: #757575;
  --df-messenger-minimized-chat-close-icon-color: rgba(0, 0, 0, 0.87);
  --df-messenger-send-icon: #1e88e5;
  --df-messenger-user-message: #dddddd;
  --df-messenger-chip-color: white;
  --df-messenger-chip-border-color: #e0e0e0;
  --df-messenger-focus-color: #1e88e5;
  --df-messenger-focus-color-contrast: white;
}

.df-messenger-wrapper,
.df-messenger-wrapper img:not(.image) {
  border: 0;
  margin: 0;
  padding: 0;
}

.df-messenger-wrapper {
  background-color: #fff;
  bottom: 20px;
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  font-weight: 400;
  position: fixed;
  right: 20px;
  text-decoration: none;
  z-index: 100;
}

.df-messenger-wrapper a,
.df-messenger-wrapper button {
  cursor: pointer;
}

.df-messenger-wrapper svg {
  fill: rgba(0, 0, 0, 0.87);
  margin: 0;
  padding: 0;
}

button#widgetIcon {
  border: none;
  border-radius: 30%;
  bottom: 0;
  box-shadow: rgba(0, 0, 0, 0.24) 1px 4px 15px 0;
  cursor: pointer;
  height: 56px;
  position: absolute;
  right: 0;
  width: 56px;
}

button#widgetIcon:focus-visible::after {
  border-radius: 40px;
  border: 3px solid var(--df-messenger-focus-color);
  height: 60px;
  left: -5px;
  top: -5px;
  width: 60px;
}

button#widgetIcon:focus,
button#widgetIcon:focus-visible {
  outline: 0;
}

button#widgetIcon .df-chat-icon {
  height: 36px;
  left: 10px;
  opacity: 1;
  position: absolute;
  top: 10px;
  transition: opacity 0.5s;
  width: 36px;
}

button#widgetIcon div.rotate-fade #closeSvg {
  opacity: 1;
  transform: rotate(-90deg);
}

button#widgetIcon div #closeSvg {
  fill: #fff;
  fill: var(--df-messenger-button-titlebar-font-color);
  left: 15px;
  opacity: 0;
  position: absolute;
  top: 15px;
  transition:
    transform 0.5s,
    opacity 0.5s;
}

.small-screen-only {
  display: none;
}

@media screen and (max-width: 500px) {
  .expanded #widgetIcon,
  .minimized #widgetIcon {
    visibility: hidden;
    display: none;
  }

  .expanded > .small-screen-only {
    display: block;
  }
}

.visually-hidden {
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
}

.focus-outline:focus-visible,
.focus-outline-round:focus-visible,
.focus-outline-contrast:focus-visible {
  outline: none;
  position: relative;
}

.focus-outline:focus-visible:before {
  border-radius: 8px;
  border: 3px solid var(--df-messenger-focus-color);
  bottom: 0;
  box-sizing: border-box;
  content: '';
  left: 0;
  outline: none;
  position: absolute;
  right: 0;
  top: 0;
}

.focus-outline-round:focus-visible:before {
  /* border-radius: 20px; */
  border: 3px solid var(--df-messenger-focus-color);
  bottom: 0;
  box-sizing: border-box;
  content: '';
  left: 0;
  outline: none;
  position: absolute;
  right: 0;
  top: 0;
}

.focus-outline-contrast:focus-visible:before {
  /* border-radius: 8px; */
  border: 3px solid var(--df-messenger-focus-color-contrast);
  bottom: 0;
  box-sizing: border-box;
  content: '';
  left: 0;
  outline: none;
  position: absolute;
  right: 0;
  top: 0;
}

.focus-outset:focus-visible:before {
  /* margin: -5px; */
}

.focus-circle:focus-visible:before {
  /* border-radius: 50%; */
}

.focus-inset:focus-visible:before {
  /* margin: 5px; */
}

/* chat */

.chat-wrapper {
  background-color: #e5e5e5;
  border-radius: 4px;
  bottom: 105px;
  box-shadow: rgba(0, 0, 0, 0.24) 1px 4px 15px 0;
  display: flex;
  flex-direction: column;
  height: 0;
  opacity: 0;
  position: fixed;
  right: 20px;
  /*  transform: translateX(25%) translateY(35%) scale(.5, .5);
  transition: transform .2s, opacity .2s ease-in, height .2s; */
  width: 370px;
}

.chat-min {
  background-color: #fafafa;
  bottom: 20px;
  height: 0;
  max-width: 370px;
  right: 100px;
  width: auto;
}

.chat-wrapper.chat-min.chat-open {
  height: auto;
}

.chat-wrapper.chat-open {
  height: 560px;
  opacity: 1;
  /*  transform: translate3d(0, 0, 0) scale(1, 1);
  transition: transform .2s, opacity .2s ease-in */
}

.chat-min df-message-list,
.chat-min .df-message-list {
  background-color: #fafafa;
  background-color: var(--df-messenger-chat-background-color);
}

.chat-min df-messenger-titlebar,
.chat-min df-messenger-user-input,
.chat-min .df-messenger-titlebar,
.chat-min .df-messenger-user-input {
  display: none;
}

df-message-list,
.df-message-list {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  min-height: 0;
  min-width: 250px;
}

df-messenger-titlebar,
.df-messenger-titlebar {
  z-index: 2;
}

@media screen and (max-width: 500px) {
  .chat-wrapper {
    bottom: 0;
    right: 0;
    width: 100%;
  }

  .chat-wrapper.chat-open {
    height: 100%;
  }
}

/* titlebar */
.title-wrapper {
  align-items: center;
  border-radius: 5px 5px 0 0;
  box-shadow: 0 3px 6px 0 #00000029;
  color: #fff;
  color: var(--df-messenger-button-titlebar-font-color);
  display: flex;
  font-size: 18px;
  height: 50px;
  justify-content: space-between;
  padding-left: 15px;
}

.title-wrapper h2 {
  font-size: 18px;
  font-weight: 400;
  margin: 0;
}

#minimizeIcon {
  /* fill: #fff;
  fill: var(--df-messenger-button-titlebar-font-color);
  margin: 15px;
  transform: rotate(90deg) */
}

/* @media screen and (min-width: 501px) {
  #minimizeIconButton {
    visibility: hidden
  }
} */

#dfTitleBar:focus-visible,
#minimizeIconButton:focus-visible {
  outline: 0;
}

#dfTitleBar:focus-visible:after {
  margin: -8px;
}

#minimizeIconButton:focus-visible:after {
  margin: 8px;
}

#minimizeIconButton {
  /* background-color: transparent;
  border: none */
}

/* user input */
.maximize-focus:focus-within {
  position: absolute;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  border-radius: 4px;
  border: 3px solid var(--df-messenger-focus-color);
  bottom: 0;
  box-sizing: border-box;
  content: '';
  left: 0;
  outline: none;
  position: absolute;
  right: 0;
  top: 0;
}

.input-box-wrapper,
.input-container input {
  /* background-color: var(--df-messenger-input-box-color) */
}

.input-box-wrapper {
  align-items: center;
  background-color: #fff;
  border-top: 1px solid #eee;
  display: flex;
  height: 50px;
  z-index: 2;
}

.input-container {
  display: flex;
  flex-direction: column;
  /*  border-radius: 0 0 4px 4px; */
  overflow: hidden;
}

.input-container input {
  border-radius: 0 0 4px 4px;
  border: none;
  color: rgba(0, 0, 0, 0.87);
  color: var(--df-messenger-input-font-color);
  font-size: 14px;
  padding-left: 15px;
  width: 100%;
}

input:focus {
  outline-width: 0;
}

.input-box-wrapper.show-focus {
  position: relative;
}

#sendIcon {
  cursor: pointer;
  /*fill: #42a5f5;
  fill: var(--df-messenger-send-icon); */
  flex: 0 0 auto;
  height: 24px;
  margin: 15px;
  /* viewbox: 0 0 24 24; */
  width: 24px;
}

#sendIcon:hover {
  fill: green;
}

#sendIconButton,
#sendIconButton:focus,
#sendIconButton:hover {
  align-items: center;
  background-color: transparent;
  border: none;
  display: none;
  justify-content: center;
  transform: scale(0.01, 0.01);
  transition: 0.3s;
}

#sendIconButton:focus {
  outline: 0;
}

#sendIconButton:focus-visible {
  position: relative;
}

.valid #sendIconButton {
  transform: scale(1, 1);
  display: flex;
}

.check-input {
  background-color: #e53935;
  color: #fafafa;
  font-size: 13px;
  font-weight: 700;
  height: 25px;
  line-height: 1.7;
  padding-left: 10px;
  transform: translateY(0);
  transition: transform 0.2s;
  z-index: 1;
}

.show-focus:after {
  border-radius: 8px;
  border: 3px solid var(--df-messenger-focus-color);
  bottom: 0;
  box-sizing: border-box;
  content: '';
  left: 0;
  outline: none;
  position: absolute;
  right: 0;
  top: 0;
}

/* messages */
hr {
  border: 0;
  border-top: 1px solid #e0e0e0;
  margin: 0;
}
.card-wrapper {
  /* background-color: #fff; */
  /* border-radius: 8px; */
  /* border: 1px solid #e0e0e0; */
  /* box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .24); */
  margin-top: 10px;
}

.df-chips-wrapper {
  padding: 10px;
}

.df-chips-wrapper.clicked {
  display: none;
}

.df-chips-wrapper .chip {
  align-items: center;
  /* background-color: var(--df-messenger-chip-color);
  background-color: #fff; */
  /* border: 1px solid #e0e0e0; */
  /* border-color: var(--df-messenger-chip-border-color);*/
  /* border-radius: 20px;*/
  /* box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .24); */
  display: inline-flex;
  height: 35px;
  margin: 0 10px 10px 0;
  padding: 0 16px;
}

.df-chips-wrapper .chip:hover {
  /* background: #e5e5e5 */
}

.df-chips-wrapper .chip img {
  margin-right: 8px;
  max-height: 17.5px;
  max-width: 17.5px;
}

.df-chips-wrapper a[href]:after {
  background: url("data:image/svg+xml;utf8, <svg xmlns='http://www.w3.org/2000/svg' fill='black' height='24' viewBox='0 0 24 24' width='24'> <path d='M0 0h24v24H0z' fill='none'/> <path d='M19 19H5V5h7V3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z'/> </svg>")
    center/contain no-repeat;
  content: '';
  display: inline-block;
  height: 15px;
  margin-left: 8px;
  width: 15px;
}

.title,
.title-card-elements {
  font-size: 14px;
}

.image {
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 24px;
  max-height: 24px;
  max-width: 24px;
  padding-right: 24px;
}

.title {
  color: #000;
  font-weight: 700;
  margin: 0;
}

.subtitle {
  color: #757575;
  padding-top: 8px;
}

.title-card-elements {
  /* background-color: #fff; */
  /* border-radius: 8px; */
  cursor: pointer;
  display: flex;
  padding: 16px;
}

.list-button {
  background-color: transparent;
  border: none;
  padding: 0;
  width: 100%;
}

button {
  text-align: left;
}

.link-wrapper {
  text-decoration: none;
}

.title-card-elements {
  /* background-color: #fff; */
  /* border-radius: 8px; */
  display: flex;
  padding: 16px;
}

.description-line {
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  padding-top: 8px;
  word-break: break-word;
}

#descriptionWrapper {
  /* background-color: #fff; */
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 16px;
}

img {
  width: 100%;
  border-style: none;
}

#dfButtonAnchorWrapper,
#dfButtonWrapper {
  align-items: center;
  /* background: #fff; */
  border-radius: 8px;
  box-sizing: border-box;
  color: #000;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  padding: 12px;
  text-decoration: none;
  width: 100%;
}

#dfButtonAnchorWrapper:hover > #dfLinkText,
#dfButtonAnchorWrapper:hover > .df-button-icon,
#dfButtonWrapper:hover > #dfButtonText,
#dfButtonWrapper:hover > .df-button-icon {
  opacity: 0.5;
}

.text {
  padding-left: 12px;
}

.df-button-icon,
.df-button-icon #materialButtonIcon,
.df-button-icon #materialIcon {
  height: 24px;
  width: 24px;
}

button#dfButtonWrapper {
  background: 0 0;
  border: none;
}

a#dfButtonAnchorWrapper:focus-visible,
button#dfButtonWrapper:focus-visible {
  outline: 0;
  position: relative;
}

#dfAccordionWrapper {
  font-size: 14px;
}

#dfAccordionWrapper {
  /* background: #fff; */
  border-radius: 8px;
  color: #000;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: 12px;
  text-decoration: none;
}

#dfAccordionWrapper .top-row {
  align-items: stretch;
  background-color: transparent;
  border: none;
  display: flex;
  justify-content: space-between;
}

#dfAccordionWrapper .top-row:hover {
  cursor: pointer;
}

#dfAccordionWrapper .top-row .content {
  display: flex;
}

#dfAccordionWrapper .image-content {
  display: block;
  flex-direction: column;
  justify-content: center;
  overflow: inherit;
  width: auto;
}

#dfAccordionWrapper #image {
  border-radius: 3px;
  margin-right: 10px;
  max-width: 47px;
}

#dfAccordionWrapper #title {
  color: #000;
  font-size: 14px;
  font-weight: 700;
  margin: 0;
}

#dfAccordionWrapper #subtitle {
  color: #757575;
  font-size: 13px;
  text-align: left;
}

#dfAccordionWrapper .text-content {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

#dfAccordionWrapper .text-row {
  max-height: inherit;
  transition: max-height 0.25s ease-in;
}

#dfAccordionWrapper #text {
  padding-top: 10px;
}

#dfAccordionWrapper #expandIcon {
  /*  color: #757575; */
  font-size: 32px;
  padding: 7px 0;
  transform: rotate(90deg);
  transition: transform 0.15s ease-out;
}

#dfAccordionWrapper .word-wrap {
  overflow-wrap: break-word;
  word-break: break-word;
  word-wrap: break-word;
}

#dfAccordionWrapper #expandIcon.open {
  font-size: 32px;
  padding: 7px 0;
  transform: rotate(-90deg);
  transition: transform 0.15s ease-in;
}

#dfAccordionWrapper button:focus-visible {
  outline: 0;
  position: relative;
}

button {
  text-align: left;
}
</style>
