<script setup lang="ts">
import type { RichContentMessage } from '~/plugins/supportAgent'

const showError = ref(false)
const { messenger, messageListRef, showBotWriting } = useSupportAgent()

function getMessageRounding(content: RichContentMessage, index: number) {
  if (content.type !== 'text')
    return ''

  const classes: string[] = ['rounded-2xl']
  const prevMessage = messenger.value.messages[index - 1]
  const nextMessage = messenger.value.messages[index + 1]

  if (content.isBot) {
    if (prevMessage && prevMessage.isBot && prevMessage.type === 'text')
      classes.push('!rounded-tl-md')

    if (nextMessage && nextMessage.isBot && nextMessage.type === 'text')
      classes.push('!rounded-bl-md')
  }
  else {
    if (prevMessage && !prevMessage.isBot && prevMessage.type === 'text')
      classes.push('!rounded-tr-md')

    if (nextMessage && !nextMessage.isBot && nextMessage.type === 'text')
      classes.push('!rounded-br-md')
  }

  return classes.join(' ').trim()
}
</script>

<template>
  <div class="df-message-list n-bg-base">
    <div class="message-list-wrapper" :class="{ minimized: messenger.minimized }">
      <div aria-live="polite" class="error" :class="{ show: showError }">
        Something went wrong, please try again.
      </div>

      <div id="messageList" ref="messageListRef" aria-live="polite" class="message-list">
        <span v-for="(content, outerIndex) in messenger.messages" :key="`o-${outerIndex}`" class="animation">
          <RcCard v-if="Array.isArray(content)">
            <template v-for="(richContent, innerIndex) in content" :key="`i-${innerIndex}`">
              <RcAccordion v-if="richContent.type === 'accordion'" v-bind="richContent" />
              <RcButton v-else-if="richContent.type === 'button'" v-bind="richContent" />
              <RcDescription v-else-if="richContent.type === 'description'" v-bind="richContent" />
              <RcDivider v-else-if="richContent.type === 'divider'" v-bind="richContent" />
              <RcImage v-else-if="richContent.type === 'image'" v-bind="richContent" />
              <RcListElement v-else-if="richContent.type === 'list'" v-bind="richContent" />
              <RcTitle v-else-if="richContent.type === 'info'" v-bind="richContent" />
            </template>
          </RcCard>

          <RcChips v-else-if="content.type === 'chips'" v-bind="content" />
          <TextMessage
            v-else-if="content.type === 'text'"
            v-bind="content"
            :class="getMessageRounding(content, outerIndex)"
          />
        </span>

        <span v-if="showBotWriting" class="animation">
          <TextTyping
            is-bot
            class="rounded-2xl"
            :class="messenger.messages[messenger.messages.length - 1].isBot ? 'rounded-tl-md' : ''"
          />
        </span>
      </div>
    </div>
  </div>
</template>

<style>
.message-list-wrapper.minimized {
  flex-direction: row;
}

.message-list-wrapper.minimized #messageList {
  overflow-y: hidden;
}

.message-list-wrapper.minimized #messageList .message {
  cursor: pointer;
  margin: 0;
}

.message-list-wrapper #dismissIcon,
.minimized #messageList > :not(:first-child),
.minimized .error {
  display: none;
}

.message-list-wrapper.minimized #dismissIcon {
  align-self: flex-start;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: initial;
  fill: rgba(0, 0, 0, 0.87);
  fill: var(--df-messenger-minimized-chat-close-icon-color);
  flex: 0 0 auto;
  padding: 10px;
}

.message-list-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  min-height: 0;
}

#messageList {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 10px;
}

#messageList #typing {
  font-size: 14px;
}

#messageList .message {
  /* background: #fff;
  border-radius: 20px; */
  flex: 0 0 auto;
  font-size: 14px;
  margin-top: 10px;
  max-width: calc(100% - 28px);
  padding: 7px 16px;
  word-break: break-word;
  word-wrap: break-word;
}

#messageList .animation {
  animation: 0.3s 0.1s forwards present-yourself;
  display: flex;
  flex-direction: column;
  opacity: 0;
}

#messageList > :first-child {
  margin-top: auto !important;
}

#messageList .bot-message,
#messageList .df-message.isbot,
#messageList df-message[isbot] {
  align-self: flex-start;
}

#messageList .user-message,
#messagelist df-message,
#messagelist .df-message {
  align-self: flex-end;
}

#messageList .message.bot-message {
  align-self: flex-start;
  /* background-color: #e1f5fe;
  background-color: var(--df-messenger-bot-message); */
  line-height: 1.4;
  margin-right: 75px;
}

#messageList .message.user-message {
  align-self: flex-end;
  /* background-color: #eee;
  background-color: var(--df-messenger-user-message); */
  margin-left: 75px;
}

#typing:after {
  content: '.';
  animation: 1s linear infinite fade_pulse;
}

.error {
  align-items: center;
  align-self: center;
  background-color: #000;
  box-shadow: 1px 4px 15px 0 rgba(0, 0, 0, 0.24);
  color: #fff;
  display: none;
  font-size: 12px;
  justify-content: center;
  margin-top: 0;
  opacity: 0;
  padding: 10px;
  position: absolute;
  transform: translateY(-100%);
  transition:
    transform 0.2s,
    opacity 0.2s;
  width: 95%;
  z-index: 1;
}

.error.show {
  opacity: 0.8;
  transform: translateY(0);
  display: flex;
}

@keyframes fade_pulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
}

@keyframes present-yourself {
  to {
    opacity: 1;
  }
}
</style>
