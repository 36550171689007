<script setup lang="ts">
import type { GcxQueryInput } from '#graphql-operations'

const props = defineProps<{
  type: 'list'
  title?: string
  subtitle?: string
  event?: {
    name?: string
  }
  languageCode?: string
}>()

defineEmits<{ (...args: any): void }>()

const { messenger, sendMessage } = useSupportAgent()

async function onClick() {
  if (props.event?.name) {
    const queryInput: GcxQueryInput = {
      event: { event: props.event.name },
      languageCode: props.languageCode ?? messenger.value.languageCode ?? 'en',
    }

    await sendMessage(queryInput)
  }
}
</script>

<template>
  <div class="df-list-element" role="listitem">
    <button
      class="title-card-elements list-button inline-flex cursor-pointer border-none bg-transparent align-bottom c-slate-900 decoration-none focus-outline"
      @click="onClick"
    >
      <div v-if="props.title || props.subtitle" class="text-container">
        <h3 v-if="props.title" class="title">
          {{ props.title }}
        </h3>

        <div v-if="props.subtitle" class="subtitle">
          {{ props.subtitle }}
        </div>
      </div>
    </button>
  </div>
</template>
