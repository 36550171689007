<script setup lang="ts">
import type { GcxQueryInput, GcxQueryParameters } from '#graphql-operations'

const props = defineProps<{
  type: 'button'
  icon?: {
    type?: string
    color?: string // hex code
  }
  image?: {
    src?: {
      rawUrl?: string
      accessibilityText?: string
    }
  }
  text?: string
  link?: string
  event?: {
    name?: string
    parameters?: { [key: string]: unknown }
  }
  mode?: 'blocking' | string
  languageCode?: string
}
>()

defineEmits<{ (...args: any): void }>()

const { messenger, sendMessage } = useSupportAgent()
const { t } = useI18n()

async function onClick() {
  if (props.event?.name) {
    const queryInput: GcxQueryInput = {
      event: { event: props.event.name },
      languageCode: props.languageCode ?? messenger.value.languageCode ?? 'en',
    }

    const queryParams: GcxQueryParameters = {
      parameters: props.event.parameters || {},
    }

    await sendMessage(queryInput, queryParams)
  }
}
</script>

<template>
  <div class="df-button">
    <a
      id="dfButtonAnchorWrapper"
      class="inline-flex cursor-pointer border-none bg-transparent align-bottom c-slate-900 decoration-none focus-outline"
      target="_blank"
      rel="noopener noreferrer"
      :href="props.link ?? '#'"
      @click.prevent="onClick"
    >
      <template v-if="icon?.type || image?.src?.rawUrl">
        <div v-if="icon?.type" class="df-button-icon">
          <NIcon id="materialButtonIcon" class="text-context" aria-hidden="true" icon="i-heroicons:chevron-right-20-solid" />
        </div>
        <div v-else-if="image?.src?.rawUrl" class="df-button-image h-10 w-10 shrink-0 overflow-hidden rounded-lg bg-slate-200 sm:h-14 sm:w-14">
          <img
            class="h-full w-full object-cover object-center"
            :src="image.src.rawUrl"
            :alt="image.src?.accessibilityText ?? text"
          >
        </div>
      </template>

      <span class="text">
        {{ props.text }}
      </span>

      <span class="sr-only">
        {{ t('chat.opens_in_a_new_tab') }}
      </span>
    </a>
  </div>
</template>
